.graphical-infos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  .graphical-infos-item {
    background-color: var(--background-color-light);
    border-radius: 1.2rem;
    padding: 2.5rem;
    flex-basis: calc(33.3% - 1rem);
    .chart-container {
      display: flex;
    }
  }
}
