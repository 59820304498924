.form-input {
  position: relative;
  cursor: text;
  .textarea {
    min-height: 6rem;
    padding: 2rem;
    font-size: 1.4rem;
    border: none;
    width: 100%;
    outline: none;
    background-color: var(--background-color-light);
    color: var(--clr-neutral-100);
    border-radius: 1.2rem;
    color-scheme: var(--theme);
    &::placeholder {
      color: var(--clr-neutral-100);
    }
  }
  .textarea::-webkit-outer-spin-button,
  .textarea::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
