.part-table {
  background-color: var(--background-color-light);
  font-size: 1.4rem;
  line-height: 17px;
  border: 1px solid var(--clr-neutral-800);
  border-radius: 3rem;
  .table-header-container {
    @media screen and (max-width: 1200px) {
      display: none;
    }
    .table-header {
      padding: 1rem;
      display: flex;
      border-bottom: 1px solid rgba(101, 101, 101, 1);
      .table-header-item {
        cursor: pointer;
      }
      & > div {
        display: flex;
        align-items: center;
      }
      .table-img-header {
        flex-basis: 5%;
      }
      .table-quantity-header {
        flex-basis: 8%;
      }
      .table-part-number-header {
        flex-basis: 11%;
      }
      .table-part-name-header {
        flex-basis: 15%;
      }
      .table-brand-header {
        flex-basis: 12%;
      }
      .table-model-header {
        flex-basis: 12%;
      }
      .table-year-header {
        flex-basis: 7%;
      }
      .table-listing-id-header {
        flex-basis: 8%;
      }
      .table-part-type-header {
        flex-basis: 12%;
      }
      .table-status-header {
        flex-basis: 10%;
      }
    }
  }

  .table-content-container {
    .table-content {
      display: flex;
      padding: 1rem;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: var(--background-color-dark);
      }
      &:last-child {
        border-bottom-right-radius: 3rem;
        border-bottom-left-radius: 3rem;
      }

      @media screen and (max-width: 1200px) {
        display: grid;
        grid-template-columns: 1fr 7fr;
        grid-template-rows: 1fr 1fr 1fr 0.7fr 1fr 1fr 0.5fr 1fr;
        padding: 2rem 1rem;
        &:first-child {
          border-top-right-radius: 3rem;
          border-top-left-radius: 3rem;
        }
      }
      @media screen and (max-width: 700px) {
        //grid-template-columns: 2fr 7fr;
      }
      @media screen and (max-width: 410px) {
        //grid-template-columns: 2fr 7fr;
      }
      &:not(:last-child) {
        border-bottom: 1px solid var(--clr-neutral-800);
      }
      .table-content-item {
        line-height: 1.5rem;
        display: flex;
        gap: 0.5rem;
        @media screen and (max-width: 700px) {
          //padding: 0.5rem;
        }
        .table-content-title {
          display: none;
          @media screen and (max-width: 1200px) {
            display: block;
          }
        }
      }

      .table-img-content {
        flex-basis: 5%;
        @media screen and (max-width: 1200px) {
          grid-column: 1 / 2;
          grid-row: 2/8;
          margin-right: 2rem;
          min-width: 8rem;
        }
        @media screen and (max-width: 700px) {
          // margin-right: 0.5rem;
          //min-width: 6rem;
          //grid-row: 2/6;
        }
        @media screen and (max-width: 410px) {
          //grid-row: 2/5;
        }
        img {
          width: 4rem;
          height: 4rem;
          border-radius: 50%;
          @media screen and (max-width: 1200px) {
            width: 7.5rem;
            height: 7.5rem;
          }
          @media screen and (max-width: 410px) {
            //width: 7rem;
            //height: 7rem;
          }
        }
      }
      .table-quantity-content {
        flex-basis: 8%;
        @media screen and (max-width: 1200px) {
          grid-column: 2/3;
          grid-row: 5/6;
        }
      }
      .table-part-number-content {
        flex-basis: 11%;
        @media screen and (max-width: 1200px) {
          grid-column: 2/3;
          grid-row: 2/3;
        }
      }
      .table-part-name-content {
        flex-basis: 15%;
        @media screen and (max-width: 1200px) {
          grid-column: 2/3;
          grid-row: 1/2;
          font-weight: 600;
        }
      }
      .table-brand-content {
        flex-basis: 12%;
        @media screen and (max-width: 1200px) {
          visibility: hidden;
        }
      }
      .table-model-content {
        flex-basis: 12%;
        @media screen and (max-width: 1200px) {
          visibility: hidden;
        }
      }
      .table-year-content {
        flex-basis: 7%;
        @media screen and (max-width: 1200px) {
          grid-column: 2/3;
          grid-row: 6/7;
        }
      }
      .table-listing-id-content {
        flex-basis: 8%;
        @media screen and (max-width: 1200px) {
          grid-column: 2/3;
          grid-row: 7/8;
        }
      }
      .table-part-type-content {
        flex-basis: 12%;
        @media screen and (max-width: 1200px) {
          grid-column: 2/3;
          grid-row: 4/5;
        }
      }

      .table-status-content {
        display: flex;
        align-items: center;
        gap: 1rem;
        flex-basis: 10%;
        @media screen and (max-width: 1200px) {
          grid-column: 2/3;
          grid-row: 8/9;
        }
        @media screen and (max-width: 700px) {
          //place-self: start;
        }
      }
      .table-brand-model-content {
        display: none;
        
        @media screen and (max-width: 1200px) {
          display: block;
          grid-column: 2/3;
          grid-row: 3/4;
        }
        @media screen and (max-width: 700px) {
          //padding: 0.4rem;
        }
      }
    }
  }
}
