.setting-items-container {
  height: calc(100vh - 15.7rem);
  width: 50rem;
  background-color: var(--background-color-light);
  border-radius: 3rem;
  @media screen and (max-width: 1200px) {
    width: auto;
    height: auto;
  }
  .setting-item-container {
    position: relative;
    @media screen and (max-width: 1200px) {
      &:last-child {
        .setting-item {
          border-bottom: none;
        }
      }
    }
    .setting-item {
      cursor: pointer;
      padding: 2.3rem;
      border-bottom: 1px solid var(--clr-neutral-800);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .setting-item-dropdown {
      display: none;
      position: absolute;
      top: 40%;
      right: -15%;
      width: 19rem;
      background-color: var(--background-color-light);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 1.2rem;
      padding: 2.2rem 1.2rem;
      z-index: 1;
      @media screen and (max-width: 1200px) {
        right: 0;
      }
      .dropdown-item {
        cursor: pointer;
        &.language {
          display: flex;
          align-items: center;
          gap: 1rem;
          img {
            width: 1.5rem;
          }
        }
      }
      &.open {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
}
