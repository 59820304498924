.purchase-tab {
  .total-price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.2rem;
    padding: 2.4rem 0;
    width: 100%;
    background-color: var(--background-color-light);
    border-radius: 1.2rem;
    .total-price-content {
      font-weight: 700;
      font-size: 2rem;
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
}
