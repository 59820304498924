.modal-header {
  padding: 6rem 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title-container{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .modal-title {
      font-weight: 700;
      font-size: 3.2rem;
    }
  }
  
  .modal-close {
    line-height: 0;
    cursor: pointer;
  }
}
