.add-accessuar-modal-container {
  z-index: 10110;
  .accessuar-transfer-operations {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .accessuar-transfer-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: center;
    flex-grow: 1;
    background-color: var(--background-color-light);
    padding: 1.5rem;
    border-radius: 1.2rem;
    cursor: pointer;
    color: inherit;
    outline: none;
    border: none;
  }
  .transfer-accessory {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .accessuar-transfer-item {
      flex-grow: 1;
    }
  }
}
