.add-device-container {
  .accessuar-information-container {
    margin-top: 4rem !important;
    .accessuar-information-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .add-svg-container {
        cursor: pointer;
        line-height: 0;
      }
    }
    .accessuar-information-body {
      .accessuar-information-item {
        border: 1px solid var(--background-color-light);
        border-radius: 1.2rem;
        padding: 2.7rem;
      }
      .button-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 2.4rem;
        button {
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          gap: 1rem;
          background-color: transparent;
          color: inherit;
          cursor: pointer;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 0;
          &.delete {
            color: var(--clr-red);
          }
        }
      }
    }
  }
}
