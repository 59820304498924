.recent-operation-list-modal-container {
  .search-input-container {
    display: flex;
    align-items: center;
    flex-grow: initial;
    gap: 2rem;
    & > * {
      line-height: 0;
    }
    .search-input {
      flex-basis: 90%;
    }
    .calendar-container {
      flex-basis: 10%;
      width: 3rem;
      aspect-ratio: 1;
      border-radius: 50%;
      background-color: var(--clr-blue);
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .date-picker-container {
        position: absolute;
        right: 0;
        top: 6rem;
      }
    }
  }
  .settings-modal-body {
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .recent-operation-item {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        border-radius: 1.2rem;
        background-color: var(--background-color-light);
        padding: 2rem;
      }
    }
  }
}
