.form-select-option {
  position: relative;

  .dropbtn {
    color: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    border: none;
    cursor: pointer;
    background: var(--background-color-light);
    border-radius: 1.2rem;
    width: 100%;
    text-align: left;
    position: relative;
    height: 6rem;
    .selected-value {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      color: var(--clr-neutral-100);
    }
    .label-text {
      padding-left: 0rem;
    }
    .more-icon-container {
      line-height: 0;
      margin-left: auto;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--background-color-light);
    min-width: 230px;
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    width: 100%;
    max-height: 40rem;
    border-radius: 1.2rem;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #30394c;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #212735;
      border-radius: 2rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #212735;
    }
    & > * {
      padding: 1.5rem 2rem;
    }
    span {
      text-decoration: none;
      display: block;
      cursor: pointer;
      &:hover {
        background-color: #404a5e;
      }
    }
    &.show {
      display: block;
    }
  }
}
