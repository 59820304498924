.add-product-modal-container {
  z-index: 10100;
  .modal-content {
    .tab {
      margin: 0 auto;
      width: 70%;
      display: flex;
      justify-content: space-between;
      background-color: var(--background-color-light);
      padding: 2rem 4rem;
      border-radius: 3rem;
      margin-bottom: 6rem;
      .tab-item {
        cursor: pointer;
        font-weight: 700;
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .active-tab {
        color: var(--clr-blue);
        svg {
          fill: var(--clr-blue);
        }
      }
    }
  }
}
