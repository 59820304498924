.qr-reader {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2147483645;
  video {
    height: 100vh !important;
    width: 100vw !important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .close-icon-container {
    right: 2rem;
    top: 2rem;
    position: absolute;
    z-index: 2147483646;
  }
}
