.e-commerce-container {
  margin: 0 4rem;
  .e-commerce-item {
    background-color: var(--background-color-light);
    border-radius: 1.2rem;
    padding: 2.5rem;
    display: flex;
    gap: 4rem;
    .e-commerce-img-container {
      flex-basis: 30%;
    }
    .e-commerce-info {
      flex-basis: 70%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .e-commerce-title {
        font-weight: 700;
      }
      .price {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
      .list-button {
        background-color: transparent;
        display: inherit;
        color: inherit;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }
    }
    .btn-container {
      display: flex;
      gap: 1rem;
    }
  }
}
