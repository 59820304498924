.waiting-for-approval-modal-container {
  .settings-modal-body {
    .item-container {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      .waiting-for-approval-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .first-part{
          display: flex;
          flex-direction: column;
        }
        .second-part{
          display: flex;
          align-items: center;
          .primary-button{
            background-color: transparent;
          }
          .disapprove-icon-container{
            cursor: pointer;
            &.disabled{
              pointer-events: none;
              cursor: no-drop;
            }
          }
        }
      }
    }
  }
}
