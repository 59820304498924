.swiper-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  z-index: 100000000000000;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  .img-container {
    display: flex;
    justify-content: center;
    position: relative;
    width: max-content;
    margin: 0 auto;
    .swiper-prev,
    .swiper-next {
      position: absolute;
      background-color: var(--background-color-light);
      line-height: 0;
      padding: 1rem;
      border-radius: 50%;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      &.disabled {
        & svg {
          fill: var(--clr-neutral-800);
          cursor: default;
        }
      }
    }
    .swiper-prev {
      left: 1rem;
    }
    .swiper-next {
      right: 1rem;
    }
    .close-btn {
      position: absolute;
      right: 2rem;
      top: 2rem;
      width: 4rem;
      aspect-ratio: 1;
      background-color: var(--background-color-light);
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 0;
      cursor: pointer;
      border-radius: 50%;
    }
    .download-btn {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
      background-color: var(--background-color-light);
      border-radius: 1.2rem;
      left: 1rem;
      bottom: 1rem;
      cursor: pointer;
    }
    img {
      max-height: 90vh;
      max-width: 90vw;
      border-radius: 1.2rem;
    }
  }
  .mySwiper {
    width: 100%;
    // top: 50%;
    // transform: translateY(-50%);
    .swiper-backface-hidden .swiper-slide {
      overflow: hidden;
    }
  }
  .swiper-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-overlay-color);
    z-index: -1;
  }
  .swiper-slide {
    height: initial;
  }
}
