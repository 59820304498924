.part-modal-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0 0.5rem;
  margin-bottom: 4rem;
  .tab-items {
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2.4rem;
    width: 90%;
    background-color: var(--background-color-light);
    border-radius: 3rem;
    padding: 2rem 1.8rem;
    .tab-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      cursor: pointer;
      &.active {
        color: var(--clr-blue);
        svg {
          fill: var(--clr-blue);
        }
      }
      &.not-completed {
        // .svg-icon-container {
        //   position: relative;
        //   &::before {
        //     content: "";
        //     position: absolute;
        //     width: 1rem;
        //     aspect-ratio: 1;
        //     background-color: var(--clr-red);
        //     border-radius: 50%;
        //     top: 0;
        //     right: -0.5rem;
        //   }
        // }
      }
      span {
        width: max-content;
        font-weight: 700;
      }
    }
  }
  .more-options {
    .more-icon-contanier {
      cursor: pointer;
      position: relative;
      z-index: 1000;
      .options {
        background-color: var(--background-color-light);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 1.2rem;
        display: none;
        flex-direction: column;
        gap: 1.5rem;
        position: absolute;
        left: -11rem;
        padding: 2rem 1.5rem;
        &.visible {
          display: flex;
        }
        span {
          font-weight: 500;
          width: max-content;
        }
        .flex-between {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .red-text {
          color: var(--clr-red);
        }
      }
    }
    a {
      text-decoration: none;
      color: inherit;
    }
  }
}
