.primary-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 1.2rem;
  color: var(--clr-neutral-100);
  padding: 2rem;
  width: 100%;
  cursor: pointer;
}
