.numeric-statistics {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  .numeric-statistic {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 2.5rem;
    background-color: var(--background-color-light);
    border-radius: 1.2rem;
    flex-grow: 1;
    .value {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }
  }
}
