*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  @media screen and (max-width: 1200px) {
    font-size: 55%;
  }
  @media screen and (max-width: 700px) {
    font-size: 50%;
  }
  @media screen and (max-width: 410px) {
    font-size: 45%;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4 {
  line-height: 1;
}

body,
h1,
h2,
h3,
h4,
h5,
p,
dl,
dd,
figure {
  margin: 0;
}

ul[class],
ol[class] {
  list-style: none;
  padding: 0;
  margin: 0;
}

input,
button,
textarea,
select {
  font: inherit;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: var(--br);
}
