$themes: (
  light: (
    "theme": "light",
    "clr-neutral-100": #212735,
    "clr-neutral-200": #3f3f3f,
    "clr-neutral-300": #424242,
    "clr-neutral-800": #dbdbdb,
    "clr-neutral-900": #e2e2e2,
    "background-color-dark": #bdbdbd,
    "background-color-light": #9e9e9e,
    "clr-blue": #0080ff,
    "clr-red": #ff5858,
    "checkbox-bg": #fff,
    "checkbox-checked-bg": #0080ff,
    "checkbox-color": #212735,
    "checkbox-color-disabled": #959495,
    "modal-overlay-color": rgba(33, 39, 53, 0.67),
  ),
  dark: (
    "theme": "dark",
    "clr-neutral-100": #fff,
    "clr-neutral-200": #dedede,
    "clr-neutral-300": #c3c3c3,
    "clr-neutral-800": #656565,
    "clr-neutral-900": #30394c,
    "background-color-dark": #212735,
    "background-color-light": #30394c,
    "clr-blue": #0080ff,
    "clr-red": #ff5858,
    "checkbox-bg": #fff,
    "checkbox-checked-bg": #0080ff,
    "checkbox-color": #212735,
    "checkbox-color-disabled": #959495,
    "modal-overlay-color": rgba(33, 39, 53, 0.67),
  ),
);

@each $name, $map in $themes {
  .theme--#{$name} {
    @each $key, $value in $map {
      --#{$key}: #{$value};
    }
  }
}
