.device-info-pdf-modal-container {
  z-index: 1000;
  .device-info-pdf-modal-content {
    & > div:not(:last-child) {
      margin-bottom: 4rem;
    }
  }
  .pdf-info-subtitle {
    text-align: center;
    margin-bottom: 2rem;
  }
  .pdf-info-item {
    display: flex;
    justify-content: space-between;
    border-radius: 1.2rem;
    background-color: var(--background-color-light);
    padding: 2.5rem 3rem;
  }
  .link-container{
    border: none;
    width: 100%;
    outline: none;
    background-color: var(--background-color-light);
    color: var(--clr-neutral-100);
    border-radius: 1.2rem;
    height: 6.8rem;
    padding: 2.5rem 3rem;
  }
}
