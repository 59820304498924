.input-with-qr-code-container {
  position: relative;
  .input-with-qr-code-new {
    position: relative;
    cursor: text;
    .label-text {
      padding-right: 6rem;
    }

    .html5qr-code-full-region__dashboard {
      display: none;
    }
  }
  .icon-container {
    position: absolute;
    cursor: pointer;
    line-height: 0;
    right: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  .close-icon {
    border: none !important;
    position: fixed !important;
    top: 0;
    left: 0;
    z-index: 2147483645;
  }
}
