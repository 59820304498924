.drag-and-drop-preview {
  background-color: var(--background-color-light);
  border-radius: 1.2rem;
  display: flex;
  align-items: center;
  width: 100%;
  .edit-side {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;
    gap: 7px;
    .icon-container {
      line-height: 0;
      width: 3.8rem;
      aspect-ratio: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: var(--background-color-dark);
      border-radius: 50%;
    }
  }

  .image-container {
    position: relative;
    width: 100%;
    padding: 1rem;
    overflow-x: scroll;

    border-radius: 1.2rem;
    cursor: grab;
    &.editing {
      touch-action: none;
      .img {
        cursor: move;
      }
    }
    .img {
      position: absolute;
      object-fit: cover;
      transition: all 0.2s;
      &.editing {
        &:hover {
          .delete-icon {
            display: none;
          }
        }
      }
      &:hover {
        .delete-icon {
          display: flex;
        }
      }
      
      img {
        aspect-ratio: 1;
        border-radius: 1.2rem;
      }
      .delete-icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3rem;
        aspect-ratio: 1;
        background-color: var(--background-color-dark);
        border-radius: 50%;
        display: none;
        @media screen and (max-width: 700px) {
          display: flex;
        }
      }
    }
  }
  .image-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .image-container {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
