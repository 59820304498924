.search-input {
  flex-basis: 40%;
  position: relative;

  .search-icon-container {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    line-height: 0;
  }
  input {
    background-color: transparent;
    outline: none;
    border-bottom: 1px solid var(--clr-neutral-200);
    border-top: none;
    border-left: none;
    border-right: none;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    padding-left: 3rem;
    width: 100%;
    color: inherit;
    &::placeholder {
      color: inherit;
    }
  }
}
