.e-commerce-container {
  margin: 0 4rem;
  .e-commerce-item {
    background-color: var(--background-color-light);
    border-radius: 1.2rem;
    padding: 2.5rem;
    display: flex;
    gap: 4rem;
    .e-commerce-img-container {
      flex-basis: 30%;
    }
    .e-commerce-info {
      flex-basis: 70%;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .e-commerce-title {
        font-weight: 700;
      }
      .price {
        display: flex;
        align-items: center;
        gap: 0.3rem;
      }
      .list-button {
        background-color: transparent;
        display: inherit;
        color: inherit;
        outline: none;
        border: none;
        cursor: pointer;
        padding: 0;
      }
    }
    .btn-container {
      display: flex;
      gap: 1rem;
    }
  }
}

#custom-alert-input-custom {
  display: none;
  position: fixed;
  z-index: 90000;
  &.open{
    display: block;
  }
  .reference-price-container{
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      gap: 1rem;

  }
  .custom-alert-overlay-custom {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-overlay-color);
    z-index: -1;
  }
  #dialogbox-custom {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--background-color-dark);
    padding: 2.6rem;
    border-radius: 1.2rem;
    #dialogboxhead-custom {
      font-weight: 700;
      font-size: 1.6rem;
      margin-bottom: 2.4rem;
    }
    #dialogboxbody-custom {
      font-weight: 500;
      font-size: 1.4rem;
      margin-bottom: 4rem;
    }
    #dialogboxfoot-custom {
      text-align: end;
      button {
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border: none;
        color: inherit;
        font-weight: 500;
        font-size: 1.4rem;
      }
    }
  }
}
