.dropzone-container {
  aspect-ratio: 1;
  padding-right: 1rem;
  background-color: transparent;
  position: absolute;

  .dropzone-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: max-content;
    span {
      font-weight: 700;
      font-size: 1.2rem;
    }
  }
}
