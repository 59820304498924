.add-button {
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 1.2rem;
  color: var(--clr-neutral-100);
  background-color: var(--clr-blue);
  padding: 2rem;
  width: 100%;
  cursor: pointer;
}
