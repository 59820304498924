.currency-input-container {
  position: relative;
  .currency-input {
    position: relative;
    cursor: text;

    input {
      border: none;
      width: 100%;
      outline: none;
      background-color: var(--background-color-light);
      color: var(--clr-neutral-100);
      border-radius: 1.2rem;
      color-scheme: var(--theme);
      &::placeholder {
        color: var(--clr-neutral-100);
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .formatted-text-input {
      position: absolute;
      left: 0;
      top: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
    .currencies {
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      line-height: 0;
      z-index: 0;
      button {
        cursor: pointer;
        border: none;
        outline: none;
        background: transparent;
        padding: 0;
      }
    }
  }
  .options {
    display: none;
    position: absolute;
    flex-direction: column;
    background-color: var(--background-color-light);
    width: 16.3rem;
    border-radius: 1.2rem;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);

    top: 4rem;
    right: 0;
    z-index: 1000;
    &.show {
      display: flex;
    }
    & > * {
      cursor: pointer;
      padding: 1rem;
      align-items: center;
      &:first-child {
        border-top-left-radius: 1.2rem;
        border-top-right-radius: 1.2rem;
      }
      &:last-child {
        border-bottom-left-radius: 1.2rem;
        border-bottom-right-radius: 1.2rem;
      }
      &:hover {
        background-color: #404a5e;
      }
    }
  }
}
