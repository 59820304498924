.form-input {
  position: relative;
  cursor: text;
  input {
    border: none;
    width: 100%;
    outline: none;
    background-color: var(--background-color-light);
    color: var(--clr-neutral-100);
    border-radius: 1.2rem;
    color-scheme: var(--theme);
    &::placeholder {
      color: var(--clr-neutral-100);
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="date"] {
    -webkit-appearance: none;
    text-align: left;
  }
  input::-webkit-date-and-time-value {
    text-align: left;
  }
}
