#html5qr-code-full-region {
  border: none !important;
  height: 100vh;
  width: 100vw;
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 2147483645;
  background: var(--background-color-dark);
  video {
    width: 100vw !important;
    object-fit: cover;
    height: 100vh !important;
  }
}
