.filter-btn {
  border: none;
  outline: none;
  color: inherit;
  padding: 0.8rem 1.8rem;
  font-weight: 600;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  gap: 0.7rem;
  background-color: var(--background-color-light);
  width: max-content;
  border-radius: 3rem;
  cursor: pointer;
  &.clear-filter {
    background-color: var(--clr-neutral-200);
    color: var(--clr-neutral-900);
  }
}
