@mixin animate($animation, $duration, $method, $delay, $times) {
  animation: $animation $duration $method $delay $times;
}
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
.loading-page {
  position: fixed;
  z-index: 100000;
  .loading-page-box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    gap: 5rem;
    align-items: center;
    padding: 5rem;
    background-color: var(--background-color-light);
    max-width: 30rem;
    border-radius: 1.2rem;
    .animated-circles {
      position: relative;
      width: 1.7rem;
      aspect-ratio: 1;
      @include keyframes(rotateCircle) {
        0% {
          transform: rotate(0);
        }
        15% {
          transform: rotate(-270deg);
        }
        25% {
          transform: rotate(-270deg);
        }
        40% {
          transform: rotate(-540deg);
        }
        50% {
          transform: rotate(-540deg);
        }
        65% {
          transform: rotate(-810deg);
        }
        75% {
          transform: rotate(-810deg);
        }
        90% {
          transform: rotate(-1080deg);
        }
        100% {
          transform: rotate(-1080deg);
        }
      }
      @include animate(rotateCircle, 8s, ease-out, 0s, infinite);
      span {
        width: 1.7rem;
        aspect-ratio: 1;
        background-color: #3da4d9;
        display: block;
        border-radius: 50%;
        position: absolute;

        &:nth-child(1) {
          transform: translate(1.5rem, 1.5rem);
          @include keyframes(circleToCenter) {
            0% {
              transform: translate(1.5rem, 1.5rem);
            }
            15% {
              transform: translate(1.5rem, 1.5rem);
            }
            20% {
              transform: translate(0rem, 0rem);
            }
            25% {
              transform: translate(1.5rem, 1.5rem);
            }
            40% {
              transform: translate(1.5rem, 1.5rem);
            }
            45% {
              transform: translate(0rem, 0rem);
            }
            50% {
              transform: translate(1.5rem, 1.5rem);
            }
            65% {
              transform: translate(1.5rem, 1.5rem);
            }
            70% {
              transform: translate(0rem, 0rem);
            }
            75% {
              transform: translate(1.5rem, 1.5rem);
            }
            90% {
              transform: translate(1.5rem, 1.5rem);
            }
            95% {
              transform: translate(0rem, 0rem);
            }
            100% {
              transform: translate(1.5rem, 1.5rem);
            }
          }
          @include animate(circleToCenter, 8s, ease-out, 0s, infinite);
        }
        &:nth-child(2) {
          transform: translate(1.5rem, -1.5rem);
          @include keyframes(circleToCenter2) {
            0% {
              transform: translate(1.5rem, -1.5rem);
            }
            15% {
              transform: translate(1.5rem, -1.5rem);
            }
            20% {
              transform: translate(0rem, 0rem);
            }
            25% {
              transform: translate(1.5rem, -1.5rem);
            }
            40% {
              transform: translate(1.5rem, -1.5rem);
            }
            45% {
              transform: translate(0rem, 0rem);
            }
            50% {
              transform: translate(1.5rem, -1.5rem);
            }
            65% {
              transform: translate(1.5rem, -1.5rem);
            }
            70% {
              transform: translate(0rem, 0rem);
            }
            75% {
              transform: translate(1.5rem, -1.5rem);
            }
            90% {
              transform: translate(1.5rem, -1.5rem);
            }
            95% {
              transform: translate(0rem, 0rem);
            }
            100% {
              transform: translate(1.5rem, -1.5rem);
            }
          }
          @include animate(circleToCenter2, 8s, ease-out, 0s, infinite);
        }
        &:nth-child(3) {
          transform: translate(-1.5rem, 1.5rem);
          @include keyframes(circleToCenter3) {
            0% {
              transform: translate(-1.5rem, 1.5rem);
            }
            15% {
              transform: translate(-1.5rem, 1.5rem);
            }
            20% {
              transform: translate(0rem, 0rem);
            }
            25% {
              transform: translate(-1.5rem, 1.5rem);
            }
            40% {
              transform: translate(-1.5rem, 1.5rem);
            }
            45% {
              transform: translate(0rem, 0rem);
            }
            50% {
              transform: translate(-1.5rem, 1.5rem);
            }
            65% {
              transform: translate(-1.5rem, 1.5rem);
            }
            70% {
              transform: translate(0rem, 0rem);
            }
            75% {
              transform: translate(-1.5rem, 1.5rem);
            }
            90% {
              transform: translate(-1.5rem, 1.5rem);
            }
            95% {
              transform: translate(0rem, 0rem);
            }
            100% {
              transform: translate(-1.5rem, 1.5rem);
            }
          }
          @include animate(circleToCenter3, 8s, ease-out, 0s, infinite);
        }
        &:nth-child(4) {
          transform: translate(-1.5rem, -1.5rem);
          @include keyframes(circleToCenter4) {
            0% {
              transform: translate(-1.5rem, -1.5rem);
            }
            15% {
              transform: translate(-1.5rem, -1.5rem);
            }
            20% {
              transform: translate(0rem, 0rem);
            }
            25% {
              transform: translate(-1.5rem, -1.5rem);
            }
            40% {
              transform: translate(-1.5rem, -1.5rem);
            }
            45% {
              transform: translate(0rem, 0rem);
            }
            50% {
              transform: translate(-1.5rem, -1.5rem);
            }
            65% {
              transform: translate(-1.5rem, -1.5rem);
            }
            70% {
              transform: translate(0rem, 0rem);
            }
            75% {
              transform: translate(-1.5rem, -1.5rem);
            }
            90% {
              transform: translate(-1.5rem, -1.5rem);
            }
            95% {
              transform: translate(0rem, 0rem);
            }
            100% {
              transform: translate(-1.5rem, -1.5rem);
            }
          }
          @include animate(circleToCenter4, 8s, ease-out, 0s, infinite);
        }
      }
    }
  }
  .loading-page-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-overlay-color);
    z-index: -1;
  }
}
