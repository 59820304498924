.damage-container {
  margin: 0 4rem;
  .damage-title {
    text-align: center;
  }
  .damage-list {
    .damage-item {
      display: flex;
      cursor: pointer;
      background-color: var(--background-color-light);
      border-radius: 1.2rem;
      align-items: center;
      .damage-img-container {
        flex-basis: 30%;
        img {
          width: 12rem;
          aspect-ratio: 1;
          border-top-left-radius: 1.2rem;
          border-bottom-left-radius: 1.2rem;
        }
      }
      .damage-info-container {
        flex-basis: 60%;
        display: flex;
        flex-direction: column;
        .device-serial-number {
          font-weight: 700;
        }
      }
      .damage-status-container {
        flex-basis: 10%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
