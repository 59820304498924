.checkbox-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  input[type="checkbox"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: var(--checkbox-bg);
    margin: 0;
    cursor: pointer;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 0.3rem;
    transform: translateY(-0.075em);
    padding: 0;
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--checkbox-color);
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }

  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
  input[type="checkbox"]:checked {
    background-color: var(--checkbox-checked-bg);
  }

  input[type="checkbox"]:disabled {
    --checkbox-color: var(--checkbox-color-disabled);
    color: var(--checkbox-color-disabled);
    cursor: not-allowed;
  }
}
