.header {
  position: fixed;
  width: 100%;
  z-index: 2000;
  background-color: var(--background-color-light);
  min-height: 9.7rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-bottom: 1px solid var(--clr-neutral-800);
  @media screen and (max-width: 1200px) {
    position: static;
    background-color: transparent;
    border-bottom: none;
    min-height: 5rem;
    padding-top: 2rem;
  }
  .mobile-menu-icon-container {
    display: none;
    line-height: 0;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
      display: block;
      margin-left: 2rem;
    }
  }
  .mobile-menu-title {
    display: none;
    @media screen and (max-width: 1200px) {
      display: block;
      margin-left: 2rem;
    }
  }
  .logo {
    margin-left: 2rem;
    flex-basis: 20%;
    @media screen and (max-width: 1200px) {
      display: none;
    }
    img {
      cursor: pointer;
    }
  }
  .header-search-input {
    flex-basis: 40%;
    @media screen and (max-width: 1200px) {
      flex-basis: 8%;
      order: 1;
      display: flex;
    }
    @media screen and (max-width: 600px) {
      flex-basis: 9%;
    }
    input {
      @media screen and (max-width: 1200px) {
        display: none;
      }
    }
  }
  .app-name {
    flex-basis: 27%;
    text-align: end;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  .notification {
    position: relative;
    margin-right: 1rem;
    margin-left: auto;
    cursor: pointer;
    svg {
      @media screen and (max-width: 1200px) {
        width: 2rem;
        height: 2rem;
      }
    }

    .unread-notification-exists {
      position: absolute;
      right: 0.3rem;
      top: 0rem;
      width: 1rem;
      aspect-ratio: 1;
      background-color: var(--clr-blue);
      border-radius: 50%;
      @media screen and (max-width: 1200px) {
        width: 0.6rem;
      }
    }
  }

  .add-web {
    width: 9.6rem;
    aspect-ratio: 1;
    background-color: var(--clr-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    cursor: pointer;

    @media screen and (max-width: 1200px) {
      position: fixed;
      bottom: 2.5rem;
      right: 2.5rem;
      width: 5.5rem;
      border-radius: 50%;
      z-index: 100;
    }
    svg {
      @media screen and (max-width: 1200px) {
        width: 20px;
        height: 20px;
        display: block;
      }
    }
  }
}
