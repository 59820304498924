.developers-info {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin: 20rem auto 0 auto;
  @media screen and (max-width: 1200px) {
    margin-top: 0;
  }
  img {
    width: 38rem;
    height: 3.8rem;
    cursor: pointer;
  }
  .app-name {
    font-size: 3.2rem;
    font-weight: 500;
  }
  .app-version {
    text-align: end;
    transform: translateX(10px);
    margin-bottom: 9rem;
    @media screen and (max-width: 1200px) {
      transform: translateX(0);
    }
  }
  .contact-with-devs {
    text-align: center;
  }
}
