.input-with-icon {
  position: relative;
  cursor: text;
  .label-text {
    padding-left: 6rem;
  }
  .icon-container {
    position: absolute;
    line-height: 0;
    left: 2rem;
    top: 50%;
    transform: translateY(-50%);
  }
  input {
    border: none;
    width: 100%;
    outline: none;
    background-color: var(--background-color-dark);
    color: var(--clr-neutral-100);
    padding-left: 6rem;
    border-radius: 1.2rem;
    color-scheme: var(--theme);
    &::placeholder {
      color: var(--clr-neutral-100);
    }
  }
}
