.search-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: var(--background-color-light);
  overflow: auto;
  z-index: 1000;
  .search-input-container {
    width: 50%;
    padding: 2rem;
    margin: 0 auto;
    @media screen and (max-width: 700px) {
      width: 80%;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .search-content {
    .device-table {
      border: none;
      margin-bottom: 5rem;
    }
    .table-header,
    .table-content {
      padding: 1rem 3rem;
    }
    .filter-btn {
      background-color: var(--background-color-dark);
      &.clear-filter {
        background-color: var(--clr-neutral-200);
        color: var(--clr-neutral-900);
      }
    }
    .table-filter {
      margin-right: 3rem;
    }
  }
  .close-icon-container {
    position: absolute;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    @media screen and (max-width: 700px) {
      right: 1.5rem;
    }
  }
  .device-part-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7rem;
    background-color: var(--background-color-dark);
    width: max-content;
    margin: 0 auto;
    padding: 1.7rem 4rem;
    border-radius: 3rem;
    .tab-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      &.active {
        color: var(--clr-blue);
        svg {
          fill: var(--clr-blue);
        }
      }
    }
  }
}
