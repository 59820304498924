.button-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 1.2rem;
  color: var(--clr-neutral-100);
  background-color: var(--clr-blue);
  padding: 2rem;
  width: 100%;
  margin: 2rem auto 0 auto;
  cursor: pointer;
  height: 6rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
