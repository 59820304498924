.accessories-container {
  margin: 0 4rem;
  padding: 2.5rem 1.6rem;
  border: 1px solid var(--background-color-light);
  border-radius: 1.2rem;

  .accessories-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  }
  .add-circle-container {
    cursor: pointer;
    line-height: 0;
  }
  .accessories-body {
    .accessories-item {
      border: 1px solid var(--background-color-light);
      border-radius: 1.2rem;
      padding: 2rem 0;
      .drag-and-drop-preview {
        border-radius: 0;
      }
      .print-label {
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        gap: 1rem;
      }
      .accessories-info {
        & > div {
          width: 90%;
          margin: 0 auto;
        }
      }
      .button-container {
        display: flex;
        justify-content: space-evenly;
        margin-top: 2.4rem;
        button {
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          gap: 1rem;
          background-color: transparent;
          color: inherit;
          cursor: pointer;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 0;
          &.delete {
            color: var(--clr-red);
          }
        }
      }
    }
  }
}
