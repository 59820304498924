.side-panel {
  position: fixed;
  margin-top: 9.7rem;
  height: calc(100% - 9.7rem);
  width: 27.8rem;
  background-color: var(--background-color-light);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  overflow-y: auto;
  &.closed {
    @media screen and (max-width: 1200px) {
      transform: translateX(-100%);
    }
  }
  @media screen and (max-width: 1200px) {
    transform: translateX(0);
    transition: transform 0.5s;
    margin-top: 0;
    top: 0;
    height: calc(100%);
    width: 100%;
  }
  .user-info {
    padding: 3.1rem;
    display: flex;
    align-items: center;
    gap: 2.7rem;
    border-bottom: 1px solid var(--clr-neutral-800);
    margin-bottom: 3.5rem;
    .user-info-text {
      display: block;
      max-width: 12.5rem;
    }
  }
  .side-panel-items {
    width: min-content;

    .side-panel-item {
      font-weight: 700;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 2.7rem;
      padding: 1.8rem 4.5rem;
      border-top-right-radius: 10rem;
      border-bottom-right-radius: 10rem;

      &.active {
        background-color: var(--background-color-dark);
      }
    }
  }
  .side-panel-bottom {
    margin-top: auto;
    .berner-logo {
      padding-left: 2.6rem;
      padding-bottom: 1.4rem;
      border-bottom: 1px solid var(--clr-neutral-800);
      cursor: pointer;
      img {
        max-width: 21rem;
      }
    }
    .logout {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 1.6rem;
      padding: 3.6rem 2.7rem;
    }
  }
  .close-icon-container {
    display: none;
    cursor: pointer;
    @media screen and (max-width: 1200px) {
      display: block;
      position: absolute;
      top: 4rem;
      right: 2rem;
    }
  }
  a {
    text-decoration: none;
  }
}
