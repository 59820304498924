.default-information-dropzone-container {
    aspect-ratio: 1;
    padding-right: 1rem;
    background-color: transparent;
    width: 15rem;
    .default-information-dropzone-content{
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .img-container{
        width: 15rem;
        aspect-ratio: 1;
        position: relative;
        img{
            height: 100%;
        }
        .delete-icon{
            position: absolute;
            top: 1rem;
            right: 1rem;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 3rem;
            aspect-ratio: 1;
            background-color: var(--background-color-dark);
            border-radius: 50%;
        }
    }
  
    .dropzone-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: max-content;
      span {
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
  }
  