.table-filter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  justify-content: flex-end;
  gap: 1.3rem;
  .filter-options {
    display: flex;
    flex-wrap: wrap;
    gap: 1.3rem;
    margin-right: 1.3rem;
    .filter-option {
      padding: 0.8rem 1.8rem;
      font-weight: 600;
      font-size: 1.4rem;
      background-color: var(--clr-neutral-200);
      color: var(--clr-neutral-900);
      border-radius: 3rem;
      cursor: pointer;
    }
  }
  .filter-btn-text {
    display: flex;
    align-items: center;
    gap: 1.8rem;
    span {
      font-size: 1.8rem;
    }
  }
}
