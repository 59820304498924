.product-detail-page {
  @media only screen and (max-width: 72rem) {
    height: auto;
  }
  .header {
    height: 9.7rem;
    width: 100vw;
    background-color: var(--background-color-light);
    color: var(--clr-neutral-200);
    margin-bottom: 2.7rem;
    border-bottom: 1px solid var(--clr-neutral-800);
    display: flex;
    justify-content: space-between;
    padding: 0 3rem;
    .danisik-logo {
      width: 16.2rem;
      height: 4.9rem;
    }
    .header-right-container {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .header-title {
        font-size: 2rem;
        font-weight: 700;
      }
      .berner-logo {
        width: 20.9rem;
        height: 2.1rem;
      }
    }
  }
  .product-detail-content {
    padding-top: 9.7rem + 2.7rem;
    padding-left: 2.7rem;
    padding-right: 2.7rem;
    padding-bottom: 2.7rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 2.7rem;
    grid-row-gap: 2.7rem;
    @media only screen and (max-width: 72rem) {
      display: flex;
      flex-direction: column;
      gap: 4rem;
      height: auto;
    }
    .images-container {
      grid-column: 1 / 4;
      grid-row: 1/3;
      @media only screen and (min-width: 72rem) {
        max-height: 50rem;
      }
      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .swiper {
        height: 80%;
        margin-left: initial;
      }

      .swiper-slide {
        background-size: cover;
        background-position: center;
      }

      .swiper-main .swiper-slide {
        border-radius: 12px;
        background-color: var(--background-color-light);
      }
      .swiper-main img {
        border-radius: 12px;
        width: auto !important;
      }

      .swiper-bottom {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;
      }

      .swiper-bottom .swiper-slide {
        width: 10rem !important;
        height: 100%;
        opacity: 0.4;
        border-radius: 1.2rem;
      }

      .swiper-bottom img {
        // width: 30% !important;
        border-radius: 1.2rem;
        aspect-ratio: 1;
      }

      .swiper-bottom .swiper-slide-thumb-active {
        opacity: 1;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .device-info-container {
      grid-column: 4 / 5;
      grid-row: 1 / 3;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .device-info-content {
        flex-basis: 80%;
        border-radius: 1.2rem;
        background: var(--background-color-light);
        padding: 2.8rem 2.2rem;
        color: var(--clr-neutral-200);
        h3 {
          font-size: 2.4rem;
          font-weight: 700;
          margin-bottom: 5rem;
        }
        .device-info {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          font-weight: 700;
          font-size: 2rem;
        }
      }
      .buy-btn-container{
        flex-basis: calc(20% - 1rem);
        position: relative;
        @media only screen and (max-width: 72rem) {
          min-height: 8rem;
        }
        .buy-btn {
          border-radius: 1.2rem;
          background: var(--clr-blue);
          color: var(--clr-neutral-200);
          font-size: 2rem;
          border: none;
          outline: none;
          width: 100%;
          height: 100%;
        }
        .buy-btn-dropdown-items{
          position: absolute;
          display: none;
          background-color: var(--background-color-light);
          width: 100%;
          padding: 2rem;
          border-radius: 1rem;
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
          &.open{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .dropdown-item{
              cursor: pointer;
              text-decoration: none;
            }
          }
        }
      }
      
    }
    .seller-info-container {
      grid-column: 5 / 6;
      grid-row: 1 / 3;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      color: var(--clr-neutral-200);
      .seller-info {
        border-radius: 1.2rem;
        background: var(--background-color-light);
        padding: 2.8rem 2.2rem;
        flex-basis: calc(55% - 1rem);
        .title {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1.6rem;
        }
        .company-name {
          font-size: 2.4rem;
          font-weight: 700;
        }
        .seller-name {
          font-size: 2rem;
          font-weight: 700;
          margin-bottom: 1.6rem;
        }
        .phone-info {
          display: flex;
          gap: 1rem;
          align-items: center;
          margin-bottom: 1.6rem;
        }
        .location-container {
          display: flex;
          flex-direction: column;
          gap: 0.8rem;
          .location-title {
            font-size: 2rem;
            font-weight: 700;
          }
          .location {
            display: flex;
            align-items: center;
            gap: 1rem;
            img {
              width: 4.4rem;
              height: 2.2rem;
            }
            .country-name {
              color: var(--clr-neutral-100);
              font-size: 2.4rem;
              font-weight: 400;
            }
          }
        }
      }
      .price {
        border-radius: 1.2rem;
        background: var(--background-color-light);
        padding: 2.8rem 2.2rem;
        flex-basis: 25%;
        color: var(--clr-neutral-200);
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
        .title {
          font-size: 2rem;
          font-weight: 700;
        }
        .price-container {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 2.4rem;
          font-weight: 700;
        }
      }
      .download-pdf {
        flex-basis: calc(20% - 1rem);
        background: var(--background-color-light);
        border-radius: 1.2rem;
        outline: none;
        border: none;
        color: var(--clr-neutral-200);
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.8rem;
        justify-content: center;
        font-weight: 700;
        @media only screen and (max-width: 72rem) {
          min-height: 8rem;
        }
      }
    }
    .description-container {
      grid-row: 3 / 5;
      grid-column: 1/6;
      background: var(--background-color-light);
      color: var(--clr-neutral-200);
      padding: 4.7rem 4rem;
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;
      height: fit-content;
      .title {
        font-size: 2.4rem;
        font-weight: 700;
      }
      .textarea {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        line-height: 2rem;
        background: var(--background-color-light);
        color: var(--clr-neutral-200);
        font-size: 1.4rem;
        font-weight: 500;
        resize: none
      }
    }
  }
  .quill {
    .ql-toolbar{
      display: none;
    }
    .ql-tooltip{
      display: none;
    }
    .ql-container.ql-snow{
      border: none;
    }
  }
}
