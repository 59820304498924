@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap");

:root {
  --ff-primary: "Montserrat";
  // --clr-neutral-100: #fff;
  // --clr-neutral-200: #f7f7f7;
  // --clr-neutral-300: #a9afbc;
  // --clr-neutral-400: #777;
  // --clr-neutral-500: #434956;
  // --clr-neutral-600: #333;
  // --clr-neutral-800: rgb(30, 25, 43);
  // --clr-neutral-900: #020203;
}

body {
  font-size: 1.6rem;
  font-family: var(--ff-primary);
}
div[class^="theme--"] {
  min-height: 100vh;
  background-color: var(--background-color-dark);
  color: var(--clr-neutral-100);
}

a {
  color: var(--clr-neutral-200);
  text-decoration: underline;
}

input {
  padding: 2rem;
  font-size: 1.4rem;
}
form {
  font-size: 1.4rem;
}

.flex {
  display: flex;
  gap: 1rem;
}
.align-center {
  align-items: center;
}
.line-height-zero {
  line-height: 0;
}
.flex-column {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
div[class^="device-status-"] {
  width: 2rem;
  aspect-ratio: 1;
  border-radius: 50%;
  @media screen and (max-width: 1200px) {
    width: 1rem;
  }
}
.device-status-working {
  background-color: #71ff65;
}
.device-status-broke {
  background-color: #ff5858;
}
.device-status-damaged {
  background-color: #ff5858;
}
.device-status-hardDamaged {
  background-color: #ff5858;
}
.device-status-unknown {
  background-color: #0080ff;
}
.device-status-waitingForRepair {
  background-color: #0080ff;
}
.content {
  margin-left: 28rem;
  padding-top: 12.7rem;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  @media screen and (max-width: 1200px) {
    padding: 2rem;
    margin-left: 0;
  }
}
.label-text {
  position: absolute;
  padding-left: 2rem;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.2s;
  color: var(--clr-neutral-300);
  &.date,
  &.label-small {
    top: 1.2rem;
    font-size: 1rem;
  }
}
form {
  input {
    height: 6rem;
  }
}
label {
  display: block;
}

.modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0;
  transform: translateX(100vw);
  transition: all 0.5s;
  &.open {
    transform: translateX(0);
    opacity: 1;
    margin-top: 0!important;
  }
  .modal {
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #212735;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #30394c;
      border-radius: 2rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #30394c;
    }
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    height: 100vh;
    width: 54rem;
    background-color: var(--background-color-dark);
    transition: transform 0.3s;

    @media screen and (max-width: 1200px) {
      left: 0;
      width: 100vw;
    }
    .modal-content {
      padding: 0 5rem;
      margin-bottom: 3rem;
      @media screen and (max-width: 410px) {
        padding: 0 2rem;
      }
    }
  }
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100vw - 54rem);
    height: 100vh;
    background-color: var(--modal-overlay-color);
    z-index: -1;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  &.pointer-events-none{
    
    .modal-content{
      &>*:nth-child(2){
        pointer-events: none;
      }
      .img{
        pointer-events: auto;
      }
      
    }
    .swiper-container{
      pointer-events: auto;
    }
    .delete-icon{
      visibility: hidden;
    }
  }
}

.flow-content > * + * {
  margin-top: var(--flow-spacer, 1.5rem) !important;
}

/* width */
body::-webkit-scrollbar {
  width: 10px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: #212735;
}

/* Handle */
body::-webkit-scrollbar-thumb {
  background: #30394c;
  border-radius: 2rem;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #30394c;
}

.modal-form-padding {
  padding: 0 6rem;
}

.settings-modals {
  z-index: 50000;
  .settings-modal-content {
    .modal-body-header {
      display: flex;
      align-items: center;
      gap: 2.8rem;
      .search-input-container {
        flex-grow: 1;
      }
      .add-button-container {
        cursor: pointer;
        width: 4.7rem;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: var(--clr-blue);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .settings-modal-body {
      margin-top: 6rem;
      .item-container {
        .item {
          display: flex;
          justify-content: space-between;
          padding: 1.4rem;
          border-bottom: 1px solid var(--clr-neutral-200);
          &:not(:first-child) {
            margin-top: 0.8rem;
          }
          .edit-icon-container {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.settings-modal-body {
  max-height: 90vh;
  overflow: auto;
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #212735;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #30394c;
    border-radius: 2rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #30394c;
  }
}

.Toastify__toast-container {
  z-index: 99999999999 !important;
}

.print-label {
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 1rem;
  width: 100%;
  background-color: transparent;
  color: inherit;
  border: none;
  outline: none;
}
body{
  &.open-scroll{
    overflow: hidden;
  }
}

