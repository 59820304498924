.add-part-container {
  .parts-found-count {
    font-weight: 700;
  }
  .part-info {
    display: flex;
    flex-direction: column;
    padding: 2.5rem;
    background-color: var(--background-color-light);
    border-radius: 1.2rem;
    cursor: pointer;
    font-weight: 700;
  }
}
