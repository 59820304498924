#custom-alert {
  display: none;
  position: fixed;
  z-index: 90000;
  .custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: var(--modal-overlay-color);
    z-index: -1;
  }
  #dialogbox {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--background-color-light);
    padding: 2.6rem;
    border-radius: 1.2rem;
    #dialogboxhead {
      font-weight: 700;
      font-size: 1.6rem;
      margin-bottom: 2.4rem;
    }
    #dialogboxbody {
      font-weight: 500;
      font-size: 1.4rem;
      margin-bottom: 4rem;
    }
    #dialogboxfoot {
      text-align: end;
      button {
        background-color: transparent;
        cursor: pointer;
        outline: none;
        border: none;
        color: inherit;
        font-weight: 500;
        font-size: 1.4rem;
      }
    }
  }
}
