.react-tel-input {
    margin-top: 1rem;
    .flag-dropdown {
      border: 1px solid var(--clr-primary-600);
    }
    .form-control{
        background-color: transparent;
        color: inherit;
    }
    .country-list{
        overflow-x: hidden;
        background: var(--background-color-dark);
        .country.highlight{
            background: var(--background-color-light);
            color: inherit;
        }
    }
    
  }