.recent-operations-container {
  background-color: var(--background-color-light);
  border-radius: 1.2rem;
  .recent-operations-title {
    padding: 3rem 2rem 3rem 2rem;
  }
  .recent-operations-header {
    display: flex;
    padding: 2rem;
    .description {
      flex-basis: 75%;
    }
    .time {
      flex-basis: 25%;
    }
  }
  .recent-operation-item-container {
    height: 40vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #30394c;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #212735;
      border-radius: 2rem;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #212735;
    }
    .recent-operation-item {
      display: flex;
      &:not(:last-child) {
        border-bottom: 1px solid var(--clr-neutral-800);
      }
      padding: 2rem;
      .recent-operation-item-description {
        color: var(--clr-neutral-300);
      }
      .description {
        flex-basis: 75%;
      }
      .time {
        flex-basis: 25%;
        color: var(--clr-neutral-300);
      }
    }
  }
}
