.auth-page {
  .setting-items-container {
    width: max-content;
    background-color: var(--background-color-light);
    border-radius: 3rem;
    height: auto;
    @media screen and (max-width: 1200px) {
      width: auto;
      height: auto;
    }
    .setting-item-container {
      position: relative;
      @media screen and (max-width: 1200px) {
        &:last-child {
          .setting-item {
            border-bottom: none;
          }
        }
      }
      .setting-item {
        cursor: pointer;
        padding: 2.3rem;
        border-bottom: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .setting-item-dropdown {
        display: none;
        position: absolute;
        top: 60%;
        right: -15%;
        width: 19rem;
        background-color: var(--background-color-light);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 1.2rem;
        padding: 2.2rem 1.2rem;
        z-index: 1;
        @media screen and (max-width: 1200px) {
          right: 0;
        }
        .dropdown-item {
          cursor: pointer;
          &.language {
            display: flex;
            align-items: center;
            gap: 1rem;
            img {
              width: 1.5rem;
            }
          }
        }
        &.open {
          display: flex;
          flex-direction: column;
          gap: 1rem;
        }
      }
    }
  }
  &.login-page{
    position: fixed;
    height: 100vh;
  }
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 7rem;
  .customer-logo {
    margin-top: auto;
  }
  .auth-page-content {
    width: 53.7rem;
    background-color: var(--background-color-light);
    padding: 5.6rem;
    border-radius: 1.2rem;
    display: flex;
    flex-direction: column;
    gap: 2.7rem;
    @media screen and (max-width: 700px) {
      width: 90%;
      padding: 3rem 2.2rem;
    }
    .content-title {
      font-weight: 700;
      font-size: 3.2rem;
    }
    .auth-page-form {
      display: flex;
      flex-direction: column;
      gap: 2.7rem;
    }
  }
  .footer {
    margin-top: auto;
    .developers-logo {
      width: 20rem;
    }
    .footer-text {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 1rem;
      padding: 1.5rem;
    }
  }
}
