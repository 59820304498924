.notifications-modal-container {
  .notifications-modal {
    .modal-content {
      display: flex;
      flex-direction: column;
      gap: 2.7rem;
    }
    .notification-content {
      padding: 2.7rem;
      background-color: var(--background-color-light);
      border-radius: 1.2rem;
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      &.not-read {
        position: relative;
        &::before {
          position: absolute;
          content: "";
          width: 1rem;
          aspect-ratio: 1;
          border-radius: 50%;
          background-color: var(--clr-blue);
          left: 1rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .notification-title {
        font-weight: 700;
        font-size: 1.6rem;
      }
      .notification-text {
        font-weight: 500;
        font-size: 1.4rem;
      }
    }
  }
}
