@mixin animate($animation, $duration, $method, $delay, $times) {
  animation: $animation $duration $method $delay $times;
}
@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}
.loader {
  width: 3rem;
  aspect-ratio: 1;
  border: 0.3rem solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  @include keyframes(loader) {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @include animate(loader, 1s, linear, 0s, infinite);
}
