.storage-container {
  margin: 0 4rem;
  .device-location-container {
    .label-text {
      display: none;
    }
    padding: 2.5rem 1.6rem;
    border: 1px solid var(--background-color-light);
    border-radius: 1.2rem;
    .device-location-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
    }
    .add-circle-container {
      cursor: pointer;
      line-height: 0;
    }
    .device-location-body {
      .device-location-item {
        border: 1px solid var(--background-color-light);
        border-radius: 1.2rem;
        padding: 2rem 0;

        .device-location-info {
          & > div {
            width: 90%;
            margin: 0 auto;
          }
        }
        .button-container {
          display: flex;
          justify-content: space-evenly;
          margin-top: 2.4rem;
          button {
            border: none;
            outline: none;
            display: flex;
            align-items: center;
            gap: 1rem;
            background-color: transparent;
            color: inherit;
            cursor: pointer;
            font-weight: 700;
            font-size: 1.4rem;
            line-height: 0;
            &.delete {
              color: var(--clr-red);
            }
          }
        }
      }
    }
  }
  .storages {
    margin-top: 2rem !important;
    padding: 3.2rem 1.7rem;
    border-radius: 1.2rem;
    border: 1px solid var(--background-color-light);
    .storages-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.6rem;
      .section-title {
        font-weight: 700;
        font-size: 2rem;
      }
      .add-icon {
        line-height: 0;
        cursor: pointer;
      }
    }
    .storage {
      padding: 3.4rem 1.4rem;
      border-radius: 1.2rem;
      border: 1px solid var(--background-color-light);
      .button-container {
        display: flex;
        justify-content: space-evenly;
        button {
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          gap: 1rem;
          background-color: transparent;
          color: inherit;
          cursor: pointer;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 0;
          &.delete {
            color: var(--clr-red);
          }
        }
      }
    }
  }
}
