.tab-device-form {
  .drag-and-drop-preview {
    border-radius: 0;
  }
  .physical-info {
    margin-top: 3rem !important;
    .section-title {
      font-weight: 700;
      font-size: 2rem;
    }
  }
  .technical-details {
    margin-top: 2rem !important;
    padding: 3.2rem 1.7rem;
    border-radius: 1.2rem;
    border: 1px solid var(--background-color-light);
    .technical-details-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 2.6rem;
      .section-title {
        font-weight: 700;
        font-size: 2rem;
      }
      .add-icon {
        line-height: 0;
        cursor: pointer;
      }
    }
    .technical-detail {
      padding: 3.4rem 1.4rem;
      border-radius: 1.2rem;
      border: 1px solid var(--background-color-light);
      .button-container {
        display: flex;
        justify-content: space-evenly;
        button {
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          gap: 1rem;
          background-color: transparent;
          color: inherit;
          cursor: pointer;
          font-weight: 700;
          font-size: 1.4rem;
          line-height: 0;
          &.delete {
            color: var(--clr-red);
          }
        }
      }
    }
  }
}
